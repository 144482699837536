<template>
  <div class="flex-def flex-cCenter" style="max-width: 10rem">
    <el-input size="small" v-model="value"></el-input>
    <el-color-picker size="small" v-model="value" :show-alpha="alpha"></el-color-picker>
  </div>
</template>

<script>
export default {
  name: "y_choose_color",
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal: {
      type:String,
      default:""
    },
    alpha:{
      type:Boolean,
      default: false,
    }
  },
  data() {
    return {
      value: this.modelVal,
    }
  },
  watch:{
    modelVal(){
      this.value = this.modelVal;
    },
    value(e){
      this.$emit('change',e);
    }
  },
}
</script>
